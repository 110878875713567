import DashboardForm from './DashboardForm'
import EnvironmentForm from './EnvironmentForm'
import QForm from './QForm'
import ThemeForm from './ThemeForm'
import getDashboardListAdmin from 'Admin/Configuration/use-cases/get-dashboard-list-admin-use-case'
import saveDashboardList from 'Admin/Configuration/use-cases/save-dashboard-list-use-cases'
import Roles from 'config/Roles'
import { Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import React, { useEffect, useRef, useState } from 'react'

const QuickSightEnvironment = ({ repoFactory, setIsLoading, isQEnabled }) => {
  const envFormRef = useRef()
  const dashboardFormRef = useRef()
  const themeFormRef = useRef()
  const qFormRef = useRef()

  const [existingDashboardList, setExistingDashboardList] = useState([])
  const [region, setRegion] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [existingThemes, setExistingThemes] = useState(null)

  const [error, setError] = useState(false)

  const getDashboards = () => {
    setIsLoading(true)
    getDashboardListAdmin(
      {
        token: localStorage.getItem('authToken')
      },
      {
        biRepo: repoFactory.biRepo(),
        observer: {
          receiveDashboardList: (response) => {
            const dashboardList = response.dashboardList || []
            setExistingDashboardList(dashboardList)
            setRegion(response.accountRegion)
            setAccountNumber(response.accountId)
            setExistingThemes(response.themes || null)
            setIsLoading(false)
            setError(false)
          },
          errorWhileGettingDashboardList: () => {
            setExistingDashboardList([])
            setRegion('')
            setAccountNumber('')
            setExistingThemes(null)
            setIsLoading(false)
            setError(true)
          }
        }
      }
    )
  }

  useEffect(() => {
    getDashboards()
  }, [repoFactory])

  const save = async () => {
    setError(false)
    const dashboardForm = dashboardFormRef.current
    const envForm = envFormRef.current
    const themeForm = isQEnabled ? themeFormRef.current : null // Depends on Q being enabled
    const qForm = isQEnabled ? qFormRef.current : null

    // Early return if required forms aren't available
    if (!dashboardForm || !envForm) {
      setError(true)
      return
    }

    try {
      // Submit all available forms
      await Promise.all([
        dashboardForm.submitForm(),
        envForm.submitForm(),
        ...(isQEnabled ? [themeForm.submitForm(), qForm.submitForm()] : [])
      ])

      // Check form validity
      const isValid = dashboardForm.isValid && envForm.isValid && (!isQEnabled || (themeForm.isValid && qForm.isValid))

      if (!isValid) {
        return
      }

      setIsLoading(true)

      // Helper function to clean items
      const cleanItem = (item) => {
        const { isSaved, ...cleanObj } = item
        return cleanObj
      }

      // Create dashboardList
      const dashboardList = [
        ...dashboardForm.values.dashboardList.map(cleanItem),
        ...(isQEnabled && qForm.values.enableAmazonQTopics ? qForm.values.topicList.map(cleanItem) : [])
      ]

      // Create update object
      const updateQSList = {
        dashboardList,
        accountId: envForm.values.account,
        accountRegion: envForm.values.region,
        token: localStorage.getItem('authToken')
      }

      // Add theme configuration only when Q is enabled
      if (isQEnabled) {
        updateQSList.enableThemes = themeForm.values.enableThemes
        if (themeForm.values.enableThemes) {
          updateQSList.themes = {
            darkThemeId: themeForm.values.darkThemeId,
            lightThemeId: themeForm.values.lightThemeId
          }
        }
      }

      await saveDashboardList(updateQSList, {
        biRepo: repoFactory.biRepo(),
        observer: {
          success: (response) => {
            getDashboards()
            setIsLoading(false)
            toast.success('QuickSight environment saved successfully.')
          },
          failure: (e) => {
            toast.error('QuickSight environment failed to save dashboard.')
            setError(true)
            setIsLoading(false)
          }
        }
      })
    } catch (error) {
      console.error('Error in save operation:', error)
      setError(true)
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="config-section">
        {error ? <div className="alert alert-danger">There was an error, please try again.</div> : null}
        <Card className="mb-3">
          <Card.Body>
            <h1>QuickSight Environment</h1>
            <hr />
            <EnvironmentForm formEl={envFormRef} region={region} accountNumber={accountNumber} />
          </Card.Body>
        </Card>

        {isQEnabled && (
          <Card className="mb-3">
            <Card.Body>
              <h1>Theme Configuration</h1>
              <hr />
              <ThemeForm formEl={themeFormRef} initialThemes={existingThemes} />
            </Card.Body>
          </Card>
        )}

        <Card className="mb-3">
          <Card.Body>
            <h1>QuickSight Dashboards</h1>
            <hr />
            <DashboardForm formEl={dashboardFormRef} data={existingDashboardList} />
          </Card.Body>
        </Card>

        {isQEnabled && (
          <Card className="mb-3">
            <Card.Body>
              <h1>Amazon Q Configuration</h1>
              <hr />
              <QForm formEl={qFormRef} data={existingDashboardList} roleOptions={roleOptions} />
            </Card.Body>
          </Card>
        )}

        <div className="text-center">
          <button className="btn btn-primary" onClick={save}>
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default QuickSightEnvironment

let roleOptions = Object.keys(Roles).map((key) => ({
  value: Roles[key],
  label: key
}))
